
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import '../styles/globals.css';
import '../styles/styles.scss';
import Script from 'next/script';
import { GTM_ID, pageView } from '../lib/gtm';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
function MyApp({ Component, pageProps }) {
    const router = useRouter();
    useEffect(() => {
        router.events.on('routeChangeComplete', pageView);
        return () => {
            router.events.off('routeChangeComplete', pageView);
        };
    }, [router.events]);
    return (<>
            <Component {...pageProps}/>
            <Script id="gtag-base" strategy="afterInteractive" dangerouslySetInnerHTML={{
            __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM_ID}');
          `
        }}/>
    {/*            <Script*/}
    {/*                id="chat_script"*/}
    {/*                strategy="afterInteractive"*/}
    {/*                dangerouslySetInnerHTML={{*/}
    {/*                    __html: `var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();*/}
    {/*(function(){*/}
    {/*var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];*/}
    {/*s1.async=true;*/}
    {/*s1.src='https://embed.tawk.to/65f78f499317c5437128d97c/1hp7gfep7';*/}
    {/*s1.charset='UTF-8';*/}
    {/*s1.setAttribute('crossorigin','*');*/}
    {/*s0.parentNode.insertBefore(s1,s0);*/}
    {/*})();`,*/}
    {/*                }}*/}
    {/*            />*/}
        </>);
}
const __Next_Translate__Page__18e99a74ae1__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__18e99a74ae1__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  